import { Controller } from '@hotwired/stimulus'
import { useClickOutside, useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'button',
    'list',
  ]

  connect() {
    useClickOutside(this)
    useTransition(this, { element: this.listTarget })

    this.ariaHide()
    this.listTarget.classList.add(
      'absolute',
      'backdrop-blur-lg',
      'bg-stone-100/50',
      'flex',
      'flex-col',
      'min-w-full',
      'shadow',
      'z-30',
    )
  }

  clickOutside(event) {
    this.leave()
  }

  toggle(event) {
    this.toggleTransition()
  }

  enter(event) {
    this.enter()
    this.ariaShow()
  }

  leave(event) {
    this.leave()
    this.ariaHide()
  }

  ariaShow() {
    this.buttonTarget.ariaExpanded = true
    this.listTarget.ariaHidden = false
  }

  ariaHide() {
    this.buttonTarget.ariaExpanded = false
    this.listTarget.ariaHidden = true
  }
}
