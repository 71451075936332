import { PropTypes } from 'prop-types'
import React, { forwardRef, useCallback } from 'react'
import { useActive, useCommands } from '@remirror/react'

import Button from './Button'

const BlockquoteButton = (props) => {
  const { toggleBlockquote } = useCommands()

  const active = useActive().blockquote()
  const enabled = toggleBlockquote.enabled()
  const onClick = useCallback(() => toggleBlockquote(), [toggleBlockquote])

  return (
    <Button active={active} enabled={enabled} onClick={onClick} {...props}>
      “
    </Button>
  )
}

const HeadingButton = (props) => {
  const { toggleHeading } = useCommands()
  const options = { level: 2 };

  const active = useActive().heading(options)
  const enabled = toggleHeading.enabled(options)
  const onClick = useCallback(() => toggleHeading(options), [toggleHeading])

  return (
    <Button active={active} enabled={enabled} onClick={onClick} {...props}>
      H
    </Button>
  )
}

const BoldButton = (props) => {
  const { toggleBold } = useCommands()

  const active = useActive().bold()
  const enabled = toggleBold.enabled()
  const onClick = useCallback(() => toggleBold(), [toggleBold])

  return (
    <Button active={active} enabled={enabled} onClick={onClick} {...props}>
      B
    </Button>
  )
}

const ItalicButton = (props) => {
  const { toggleItalic } = useCommands()

  const active = useActive().italic()
  const enabled = toggleItalic.enabled()
  const onClick = useCallback(() => toggleItalic(), [toggleItalic])

  return (
    <Button active={active} enabled={enabled} onClick={onClick} {...props}>
      I
    </Button>
  )
}

const EditorMenu = () => {
  return (
    <div className="flex flex-row mx-3 my-1 space-x-1 absolute">
      <div role="group">
        <HeadingButton left={true} />
        <BlockquoteButton right={true} />
      </div>

      <div role="group">
        <BoldButton left={true} />
        <ItalicButton right={true} />
      </div>
    </div>
  )
}

export default EditorMenu;
