export default class {
  constructor(container) {
    this.bar = document.createElement('div')
    this.bar.className = 'bg-stone-200 h-2 my-2 rounded-full w-full'

    this.inner = document.createElement('div')
    this.inner.className = 'bg-blue-500 h-full rounded-full'
    this.inner.style = `width: 5%;`

    this.bar.appendChild(this.inner)
    container.appendChild(this.bar)
  }

  failed() {
    this.inner.classList.remove('bg-blue-500')
    this.inner.classList.add('bg-red-500')
  }

  loaded() {
    this.bar.remove()
  }

  update(current, total) {
    const percent = (current / total) * 100
    this.inner.style = `width: ${percent}%;`
  }
}
