import { Controller } from '@hotwired/stimulus'
import React from 'react'
import { createRoot } from 'react-dom/client'

import Editor from '../components/Editor'

export default class extends Controller {
  static targets = ['data', 'html', 'editor', 'progress', 'file', 'text']

  static values = {
    data: Object,
    previewPath: String,
  }

  connect() {
    this.root = createRoot(this.editorTarget)

    this.root.render(
      <Editor
        field={this.element}
        value={this.dataValue}
        onChangeData={this.onChangeData}
        onChangeHTML={this.onChangeHTML}
        onChangeText={this.onChangeText}
        fileInput={this.fileTarget}
        progressContainer={this.progressTarget}
        previewPath={this.previewPathValue}
      />
    )
  }

  disconnect() {
    this.root.unmount()
  }

  focus = event => {
    const focus = new Event('focus')
    this.element.dispatchEvent(focus)
    event.preventDefault()
  }

  onChangeData = data => {
    this.updateImageReferences(data)
    this.dataTarget.value = JSON.stringify(data)
  }

  onChangeHTML = html => {
    this.htmlTarget.value = html
  }

  onChangeText = text => {
    this.textTarget.value = text
  }

  updateImageReferences(data) {
    this.removeImageReferences()
    this.forEachImage(data, this.addImageReference)
  }

  addImageReference = ({ attrs: { signedID } }) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('value', signedID)
    input.name = this.fileTarget.name
    this.fileTarget.form.appendChild(input)
  }

  removeImageReferences() {
    this
      .fileTarget
      .form
      .querySelectorAll(
        `input[type="hidden"][name="${this.fileTarget.name}"]:not([value=""])`
      )
      .forEach(input => input.remove())
  }

  forEachImage(object, callback) {
    if (object.content) {
      object.content.forEach(child => {
        if (child.type == 'image') {
          callback(child)
        } else {
          this.forEachImage(child, callback)
        }
      })
    }
  }
}
