import { Controller } from '@hotwired/stimulus'
import { useMeta } from 'stimulus-use'

export default class extends Controller {
  static metaNames = ['csrf-token']

  static targets = [
    'failed',
    'button',
    'loading',
    'loadingIcon',
    'subscribedIcon',
    'unsubscribedIcon',
  ]

  static values = {
    loading: { type: Boolean, default: false },
    subscriptionPath: String,
    subscribed: Boolean,
  }

  connect() {
    useMeta(this)

    if (this.subscribedValue) {
      this.subscribedIconTarget.classList.remove('hidden')
    } else {
      this.unsubscribedIconTarget.classList.remove('hidden')
    }
  }

  toggle(event) {
    console.log('toggle')
    this.loadingValue = true

    this.updateMembership({ subscribed: !this.subscribedValue })
      .then(({ subscribed }) => {
        this.loadingValue = false
        this.subscribedValue = subscribed
      }, _error => this.failed())
  }

  failed() {
    this.buttonTarget.classList.add('hidden')
    this.loadingTarget.classList.add('hidden')
    this.failedTarget.classList.remove('hidden')
  }

  loadingValueChanged(loading) {
    if (loading) {
      this.buttonTarget.classList.add('hidden')
      this.loadingTarget.classList.remove('hidden')
    } else {
      this.loadingTarget.classList.add('hidden')
      this.buttonTarget.classList.remove('hidden')
    }
  }

  subscribedValueChanged(subscribed) {
    if (subscribed) {
      this.unsubscribedIconTarget.classList.add('hidden')
      this.subscribedIconTarget.classList.remove('hidden')
    } else {
      this.subscribedIconTarget.classList.add('hidden')
      this.unsubscribedIconTarget.classList.remove('hidden')
    }
  }

  async updateMembership({ subscribed }) {
    const response = await fetch(this.subscriptionPathValue, {
      method: subscribed ? 'POST' : 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfTokenMeta,
      },
    })

    return response.json()
  }
}
