import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'input',
    'list',
    'placeholder',
    'value',
  ]

  static values = {
    isOpen: { type: Boolean, default: false },
  }

  connect() {
    this.addTopicsToList()
    useClickOutside(this)

    // retain focus when clicking children
    this.element.addEventListener('click', event => {
      this.valueTarget.focus()
    })

    // open topic list when gaining focus
    this.valueTarget.addEventListener('focus', event => {
      this.isOpenValue = true
    })

    // close topic list when another element gains focus
    this.valueTarget.addEventListener('blur', event => {
      if (event.relatedTarget !== null) this.isOpenValue = false
    })
  }

  clickOutside(event) {
    this.isOpenValue = false
  }

  focus(event) {
    this.valueTarget.focus()
  }

  isOpenValueChanged(isOpen, previousValue) {
    if (previousValue === undefined) return

    if (isOpen) {
      this.listTarget.classList.remove('hidden')
    } else {
      this.listTarget.classList.add('hidden')
    }
  }

  addTopicsToList() {
    const select = this.element.getElementsByTagName('select')[0]

    Array
      .from(select.options)
      .map(this.topicFromOption)
      .filter(topic => topic.id !== '')
      .map(topic => {
        const item = this.buildTopic(topic)
        item.addEventListener('click', event => this.selectTopic(topic))
        if (topic.selected) this.addTopic(topic)
        this.listTarget.appendChild(item)
      })
  }

  topicFromOption(option) {
    return {
      color: option.dataset['color'],
      id: option.value,
      name: option.text,
      selected: option.selected,
    }
  }

  buildTopic(topic) {
    const { color, id, name } = topic
    const item = document.createElement('li')

    item.className = `badge cursor-pointer after:bg-${color}`
    item.innerText = name
    item.dataset['value'] = id
    item.tabindex = 0

    return item
  }

  addTopic(topic) {
    const item = this.buildTopic(topic)

    item.addEventListener('click', event => {
      event.stopPropagation()
      this.deselectTopic(topic, item)
    })

    this.valueTarget.appendChild(item)
    this.showOrHidePlaceholder()
  }

  selectTopic(topic) {
    this.valueTarget.focus()

    const option = this.findOption(topic);

    if (option.selected) return
    option.selected = true

    this.addTopic(topic)
  }

  findOption(topic) {
    return this.inputTarget.querySelector(`option[value="${topic.id}"]`);
  }

  deselectTopic(topic, item) {
    item.remove()
    this.findOption(topic).selected = false
    this.valueTarget.focus()
    this.showOrHidePlaceholder()
  }

  showOrHidePlaceholder() {
    if (this.valueTarget.querySelector('.badge')) {
      this.placeholderTarget.classList.add('hidden')
    } else {
      this.placeholderTarget.classList.remove('hidden')
    }
  }
}
