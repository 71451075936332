import { PropTypes } from 'prop-types'
import React, { useCallback, useState } from 'react'
import cn from 'classnames'

const Button = ({ active, children, enabled, left, onClick, right }) => {
  const classNames = cn(
    'inline-block',
    'px-2',
    'py-1',
    'text-white',
    'text-xs',
    'uppercase',
    'w-8',
    {
      'bg-stone-300': !enabled,
      'bg-stone-400': enabled && !active,
      'hover:bg-stone-400/90': enabled && !active,
      'bg-stone-500': enabled && active,
      'hover:bg-stone-500/90': enabled && active,
      'rounded-l': left,
      'rounded-r': right,
    },
  )

  return (
    <button
      className={classNames}
      disabled={!enabled}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  left: false,
  right: false,
}

Button.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
}

export default Button;
