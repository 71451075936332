import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['colors']

  static values = {
    'color': String,
  }

  colorsOutletConnected() {
    this.update()
  }

  colorValueChanged(newColor, oldColor) {
    if (!oldColor) return
    this.element.classList.remove(this.className(oldColor))
    this.element.classList.add(this.className(newColor))
  }

  className(color) {
    return `text-${color}`
  }

  update() {
    this.colorValue = this.colorsOutlet.inputTarget.value
  }
}
