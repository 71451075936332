import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['color', 'input']
  static classes = ['selected']
  static outlets = ['colors-preview']

  connect() {
    this.selectColor(this.find(`data-color="${this.inputTarget.value}"`))
  }

  select(event) {
    this.deselectColor(this.selectedColor())
    this.selectColor(event.target)
    if (this.hasColorsPreviewOutlet) this.colorsPreviewOutlet.update()
  }

  find(selector) {
    return this.element.querySelector(`.badge[${selector}]`)
  }

  selectedColor() {
    return this.colorTargets.find(color =>
      color.classList.contains(this.selectedClass)
    )
  }

  selectColor(element) {
    element.classList.add(...this.selectedClasses)
    this.inputTarget.value = element.dataset.color
  }

  deselectColor(element) {
    element.classList.remove(...this.selectedClasses)
  }
}
