import { DirectUpload } from '@rails/activestorage'

import ProgressBar from 'components/ProgressBar'

export default class {
  constructor(file, progressContainer, input) {
    const { directUploadUrl } = input.dataset

    this.input = input
    this.directUpload = new DirectUpload(file, directUploadUrl, this)
    this.progress = new ProgressBar(progressContainer)
  }

  upload(callback) {
    this.directUpload.create((error, blob) => {
      if (error) {
        this.progress.failed()
      } else {
        this.progress.loaded()
        callback(blob)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', ({ loaded, total }) => {
      this.progress.update(loaded, total)
    })
  }
}
