require('remirror/styles/all.css')

import { PropTypes } from 'prop-types'
import React, { forwardRef, useCallback, useEffect } from 'react'
import { componentsTheme } from '@remirror/theme'

import {
  EditorComponent,
  OnChangeHTML,
  OnChangeJSON,
  Remirror,
  useDocChanged,
  useRemirror,
} from '@remirror/react'
import {
  BlockquoteExtension,
  BoldExtension,
  DropCursorExtension,
  HeadingExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  PlaceholderExtension,
} from 'remirror/extensions'

import EditorMenu from './EditorMenu'
import Uploader from './Uploader'

function uploadHandler(progressContainer, fileInput, previewPath) {
  return (files) => (
    files.map(({ file, progress }) => {
      const uploader = new Uploader(file, progressContainer, fileInput)

      return () => new Promise((resolve) => {
        uploader.upload(({ filename: fileName, signed_id: signedID }) => {
          resolve({
            fileName,
            signedID,
            src:
              previewPath
                .replace(':signed_id:', signedID)
                .replace(':filename:', fileName),
          })
        })
      })
    })
  )
}

const OnChangeText = ({ onChange }) => {
  useDocChanged(
    useCallback(
      ({ state }) => {
        const text = state.doc.textBetween(
          0,
          state.doc.content.size,
          "\n\n",
          leaf => {
            switch (leaf.type.name) {
              case 'image':
                const { title, fileName } = leaf.attrs
                return `[${title.length > 0 ? title : fileName}]`
              default:
                return ''
            }
          }
        )

        console.log('text', text)

        onChange(text)
      },
      [onChange]
    )
  )

  return null
}

const Editor = forwardRef(function Editor({
  field,
  value,
  onChangeData,
  onChangeHTML,
  onChangeText,
  progressContainer,
  fileInput,
  previewPath,
}, ref) {
  const { getContext, manager, state, onChange } = useRemirror({
    content: value,
    extensions: () => [
      new BlockquoteExtension(),
      new BoldExtension(),
      new DropCursorExtension(),
      new HeadingExtension(),
      new ImageExtension({
        enableResizing: true,
        uploadHandler: uploadHandler(progressContainer, fileInput, previewPath),
        extraAttributes: {
          signedID: {
            default: null,
            parseDOM: (dom) => {
              return dom.dataSet('signedId')
            },
            toDOM: (attrs) => {
              return ['data-signed-id', attrs.signedID]
            },
          },
        },
      }),
      new ItalicExtension(),
      new LinkExtension({ autoLink: true }),
      new PlaceholderExtension({ placeholder: 'Festina lente…' }),
    ],
  })

  useEffect(() => {
    field.addEventListener('focus', event => {
      getContext().focus('start')
    })
  }, [field, getContext])

  return (
    <Remirror
      classNames={['prose', 'prose-stone']}
      initialContent={state}
      manager={manager}
      onChange={onChange}
    >
      <div className="relative">
        <div className="absolute bg-stone-200 m-px h-[2.25rem] left-0 right-0 z-10">
          <EditorMenu />
        </div>
        <EditorComponent className="py-4" />
      </div>
      <OnChangeHTML onChange={onChangeHTML} />
      <OnChangeJSON onChange={onChangeData} />
      <OnChangeText onChange={onChangeText} />
    </Remirror>
  )
}, [])

Editor.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  onChangeHTML: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
}

export default Editor
